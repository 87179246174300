import React from "react"

const NamedDefault = ({ data }) => <>
  <hr style={{border:"1px outset",
              backgroundColor: "#08080F",
              color: "#08080F",
              height: "2px",
              width: "25%",
              textAlign: "left",
              marginLeft: "20px"
            }} />
</>
export default NamedDefault;